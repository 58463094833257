import {Box, Text} from "@chakra-ui/react";
import "../styleSheets/desktop.Icon.css"
import {OpenCloseWindow} from "./toolbar";
import {useEffect, useState} from "react";

export default function DesktopIcon(props) {

    const [selectedButton, setSelectedButton] = useState("DesktopIconButton")
    const [selectedLabel, setSelectedLabel] = useState("")
    const [selectedImage, setSelectedImage] = useState(props.src + ".png")

    function SetSelectedEventHandler(btnState, lblState, imgState) {
        setSelectedButton(btnState)
        setSelectedLabel(lblState)

        if (imgState === "DesktopIconImage")
        {
            setSelectedImage(props.src + ".png")
        }
        else
        {
            setSelectedImage(props.src + "Selected.png")
        }
    }

    useEffect(() => {

    }, [selectedButton])

    return (
        <Box className={"Icon"}>
            <button className={selectedButton}
                    id={props.id}
                    onBlur={() => {SetSelectedEventHandler(
                        "DesktopIconButton",
                        "",
                        "DesktopIconImage")}}
                    onClick={() => {SetSelectedEventHandler(
                        "DesktopIconButtonSelected",
                        "DesktopIconButtonLabelSelected",
                        "DesktopIconImageSelected")}}
                    onDoubleClick={() => {
                        SetSelectedEventHandler(
                            "DesktopIconButton",
                            "",
                            "DesktopIconImage")
                        OpenCloseWindow(props.windowIDs, props.id)
                    }}>
                <img src={selectedImage} className={"DesktopIconImage"} />
            </button>
            <label htmlFor={props.id} className={selectedLabel}>{props.label}</label>
        </Box>

)}