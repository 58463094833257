import React, {useEffect, useState} from "react";
import "../styleSheets/toolbar.Time.css"
import {Box, Text} from "@chakra-ui/react";

function ToolbarTime() {

    const [time, setTime] = useState(new Date())

    // This function will called when the component is first rendered.
    // It will update the time.
   function UpdateTime() {
       setInterval(() => {
           setTime(new Date())
       }, 1000)
   }

    return (
        <Box className="ToolBarTime">
            <Text className={"TimeText"}>{time.toLocaleTimeString('en-US',
                {hour: "numeric", minute: "2-digit", hour12: true})}</Text>
            {UpdateTime()}
        </Box>
)}

export default ToolbarTime