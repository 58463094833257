import {Box, Text} from "@chakra-ui/react";
import "../styleSheets/desktop.Icon.css"
import "../styleSheets/page.Icon.css"
import {useState} from "react";

export default function PageIcon(props) {

    const [selectedButton, setSelectedButton] = useState("WindowIconButton")
    const [selectedLabel, setSelectedLabel] = useState("WindowIconButtonLabel")
    const [selectedImage, setSelectedImage] = useState(props.src + ".png")

    function SetSelectedEventHandler(btnState, lblState, imgState) {
        setSelectedButton(btnState)
        setSelectedLabel(lblState)

        if (imgState === "DesktopIconImage")
        {
            setSelectedImage(props.src + ".png")
        }
        else
        {
            setSelectedImage(props.src + "Selected.png")
        }
    }

    return (
        <Box className={"Icon"}>
            <button className={"WindowIconButton"}
                    onClick={() => {
                        props.setdetail(props.label)
                        SetSelectedEventHandler(
                            "WindowIconButtonSelected",
                             "WindowIconButtonLabelSelected",
                            "DesktopIconImageSelected"
                        )
                    }}
                    onBlur={() => {
                        SetSelectedEventHandler(
                            "DesktopIconButton",
                             "WindowIconButtonLabel",
                            "DesktopIconImage"
                        )
                    }}>
                <img src={selectedImage} className={"WindowIconImage"}/>
            </button>
            <label htmlFor={props.id} className={selectedLabel}>{props.label}</label>
        </Box>

    )}