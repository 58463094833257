import {Stack, Text, Box} from "@chakra-ui/react";
import "../styleSheets/Main.css"
import Toolbar from "./toolbar";
import Window from "./window";
import {useEffect, useState} from "react";
import DesktopIcon from "./desktop.Icon";

function Main() {

    const [windowIDs, setwindowIDs] = useState([])

    // Grab all the windows when the website starts.
    useEffect(() => {
        let IDs = []
        const list = Array.from(document.getElementsByClassName('Window'))
        for (let i = 0; i < list.length; i++)
        {
            IDs.push(list[i].id)
        }
        setwindowIDs(IDs)
    }, [])

    return (
        <div className="Main">
            {/* If mobile view */}
            <img id={"clippy"} src={"mobile.png"}/>
           <Stack className={"MainArea"}>
               {/* Desktop Icons */}
               <Box className={"DesktopIcons"}>
                   <DesktopIcon src={"myComputerIcon"}
                                label={"About Me"}
                                windowIDs={windowIDs}
                                id={"DesktopAboutMe"}/>

                   <DesktopIcon src={"skillsIcon"}
                                label={"Skills & Abilities"}
                                windowIDs={windowIDs}
                                id={"DesktopSkills"}/>

                   <DesktopIcon src={"artifactsIcon"}
                                label={"Artifacts"}
                                windowIDs={windowIDs}
                                id={"DesktopArtifacts"}/>

                   <DesktopIcon src={"contactIcon"}
                                label={"Contact Information"}
                                windowIDs={windowIDs}
                                id={"DesktopContactInformation"}/>

                   <DesktopIcon src={"internetIcon"}
                                label={"Internet Explorer"}
                                windowIDs={windowIDs}
                                id={"Internet"}/>
               </Box>

                Windows
               <Window Title={"About Me"}
                       id={"AboutMeWindow"}
                       WindowContent={"About Me"}/>
               <Window Title={"Contact Information"}
                       id={"ContactInformationWindow"}
                       WindowContent={"Contact"}/>
               <Window Title={"Skills"}
                       id={"SkillsWindow"}
                       WindowContent={"Skills"}/>
               <Window Title={"Artifacts"}
                       id={"ArtifactsWindow"}
                       WindowContent={"Artifacts"}/>
           </Stack>
            <Toolbar windowIDs={windowIDs} />
        </div>
)}

export default Main