import "../styleSheets/toolbar.css"
import {Stack, Box} from "@chakra-ui/react";
import ToolbarTime from "./toolbar.Time";
import {useEffect, useState} from "react";



/***
 * This method will open up a window of choice and close all other windows.
 * @constructor
 */
export function OpenCloseWindow(windowList, windowName) {

    console.log(windowName)
    if (windowName === "Internet")
    {
        window.open("https://www.google.com")
        return
    }

    // First check where the method call is coming from.
    // Desktop, toolbar windows, toolbar icons, etc.
    if (windowName.includes("Desktop"))
    {
        windowName = windowName.substring(7)
        windowName = windowName + "Window"
    }
    console.log(windowName)
    if (document.getElementById(windowName).hidden === false)
    {
        document.getElementById(windowName).hidden = true
    }
    else
    {
        for (let win = 0; win < windowList.length; win++)
        {
            if (windowList[win] === windowName)
            {
                document.getElementById(windowName).hidden = false
            }
            else
            {
                document.getElementById(windowList[win]).hidden = true
            }
        }
    }
}

export default function Toolbar(props) {

    const [windowIDs, setwindowIDs] = useState([])

    useEffect(()=> {
        setwindowIDs(props.windowIDs)
    })

    return (
        <Stack isInline className={"Toolbar"}>
            <Box mr={'Auto'}>
                <Stack isInline spacing={"3px"}>
                    <Stack isInline className={"StartContainer"}>
                        <img src={"SabishiiOS_IconNOTEXT.png"} className={"StartIcon"}/>
                        <button disabled className={"StartButton"}>Start</button>
                    </Stack>

                    <Stack isInline className={"ToolBarContainer"}>
                        <img src={"myComputerIcon.png"} className={"ToolbarIcon"}/>
                        <button className={"ToolBarButton"} onClick={() =>
                        {OpenCloseWindow(windowIDs, "AboutMeWindow")}}>About Me</button>
                    </Stack>

                    <Stack isInline className={"ToolBarContainer"}>
                        <img src={"contactIcon.png"} className={"ToolbarIcon"}/>
                        <button className={"ToolBarButton"} onClick={() =>
                        {OpenCloseWindow(windowIDs, "ContactInformationWindow")}}>Contact Information</button>
                    </Stack>

                    <Stack isInline className={"ToolBarContainer"}>
                        <img src={"skillsIcon.png"} className={"ToolbarIcon"}/>
                        <button className={"ToolBarButton"} onClick={() =>
                        {OpenCloseWindow(windowIDs, "SkillsWindow")}}>Skills</button>
                    </Stack>

                    <Stack isInline className={"ToolBarContainer"}>
                        <img src={"artifactsIcon.png"} className={"ToolbarIcon"}/>
                        <button className={"ToolBarButton"} onClick={() =>
                        {OpenCloseWindow(windowIDs, "ArtifactsWindow")}}>Artifacts</button>
                    </Stack>

                </Stack>
            </Box>
            <Box ml={'Auto'} className={"TimeContainer"}>
                <ToolbarTime/>
            </Box>
        </Stack>
)}

