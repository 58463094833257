import "../styleSheets/window.css"
import {Box, Flex, Stack, Text, Link, useToast} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import * as React from "react";
import PageIcon from "./page.Icon";

/***
 * Gets my current age.
 * @constructor
 */
function GetAge() {
    const bYear = 1999
    const currentYear = new Date().getFullYear()
    return currentYear - bYear
}

function Window(props) {

    const [contactDetails, setContactDetails] = useState("")
    const toast = useToast()

    /***
     * Event handler to allow child component to update contactDetails state.
     * @param details
     * @constructor
     */
    function SetContactDetailsEventHandler(details) {
        setContactDetails(details)
    }

    return (
        <Box className={"Window"} hidden={true} id={props.id}>
            <Stack spacing={"3px"}>
                {/* Header Bar */}
                <Stack isInline className={"WindowHeaderBarStack"}>
                    <Box mr={"auto"} className={"WindowTitle"}>
                        {props.Title}
                    </Box>
                    <Box>
                        <Stack isInline>
                            <button className={"CloseButton"} onClick={() =>
                                document.getElementById(props.id).hidden = true}>X</button>
                            <button className={"MinimizeButton"} onClick={() =>
                                document.getElementById(props.id).hidden = true}>_</button>
                        </Stack>
                    </Box>
                </Stack>
            {/*  Box that contains all window contents  */}
            <Box className={"WindowContentContainer"}>
                {/*  Window Content Here  */}
                {/*  About Me  */}
                {props.WindowContent === "About Me" &&
                    <Box className={"AboutMeContent"}>
                        <Box className={"AboutMeHeaderContainer"}>
                            <img src={"aboutMeHeader.png"}/>
                            <img src={"aboutMeHeaderDivider.png"}/>
                        </Box>
                        <Box className={"AboutMeContentArea"}>
                            <Flex>
                                <Box flex={"3"} mr={"2"}>
                                    <Text fontSize={"5xl"}>Hello! My name is Zachary! \ (•◡•) /</Text>
                                    <Text fontSize={"2xl"}
                                          mb={"5"}>
                                        I am a full stack web developer and junior game designer from
                                        the great plains province of Saskatchewan Canada.
                                    </Text>
                                    <Text fontSize={"2xl"}
                                          mb={"5"}>
                                        I have had a passion in tech and programming and have been honing my
                                        skills since I could start typing on a keyboard. As soon as I could,
                                        I enrolled in all the computer related courses that I could at Tommy
                                        Douglas Collegiate including mechanical drafting, beginner programming
                                        in Python, and Game Design 30. I continued to pursue my passion after high
                                        school by attending the University of Saskatchewan to study computer science.
                                    </Text>
                                    <Text fontSize={"2xl"}>
                                        I practiced at the university for just short of three years before I decided
                                        to instead attend Saskatchewan Polytechnic Institute as a Computer Systems
                                        Technology student. I am currently enrolled at Polytechnic and have been
                                        broadening my horizons every day by learning new things, meeting amazing
                                        people, and paving a path for my future in the ever-expanding world of technology.
                                    </Text>
                                    <hr className={"HorizonalLine"} />
                                </Box>
                                <Box flex={"1"} textAlign={"center"} id={"SystemInfoPanel"}>
                                    <img src={"SabishiiOS_Icon.png"} id={"SabishiiOS"}/>
                                    <Box>
                                        <Text>Sabishii (R) OS</Text>
                                        <Text>Version 1.0 (Build 2023)</Text>
                                        <Text>Copyright (C) 1999-2023 Sabishii</Text>
                                        <hr className={"HorizonalLine"} />
                                        <Text>Full Name: Zachary Henson</Text>
                                        <Text>Age: {GetAge()}</Text>
                                        <Text>Sex: Male</Text>
                                        <Text>Pronouns: He/Him</Text>
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                    </Box>
                }
                {/* Contact Me */}
                {props.WindowContent === "Contact" &&
                    <Box className={"ContactContent"}>
                        <Stack className={"ContactContentArea"}>
                            <Box>
                                <Flex>
                                    <Box flex={"1.5"} id={"ContactLeftPane"}>
                                        <img src={"contactImage.png"} id={"ContactLeftImage"}/>
                                    </Box>
                                    <Box flex={"4"} id={"ContactRightPane"}>
                                        <Text fontSize={"6xl"} id={"ContactHeader"}>
                                            Welcome to my
                                            Contact Information
                                        </Text>
                                        <Text fontSize={"2xl"}
                                              w={"800px"}
                                              mb={"5"}>
                                            I love to work on new projects that challenge me
                                            and allow me to learn something new. I want to
                                            continue to build up my technical repertoire by working
                                            with people from all corners of the tech industry.
                                        </Text>
                                        <Text fontSize={"2xl"}
                                              w={"800px"}>
                                            Click on icons below to get more details on how to contact me or see
                                            my past work!
                                        </Text>
                                        <Box id={"ContactLinks"}>
                                            <Stack isInline spacing={"50px"}>
                                                <PageIcon src={"email_Icon"}
                                                          label={"Email"}
                                                          setdetail={SetContactDetailsEventHandler}
                                                          id={"EmailPageIcon"}/>

                                                <PageIcon src={"linkedIn_Icon"}
                                                          label={"LinkedIn"}
                                                          setdetail={SetContactDetailsEventHandler}
                                                          id={"LinkedInPageIcon"}/>

                                                <PageIcon src={"gitHub_Icon"}
                                                          label={"GitHub"}
                                                          setdetail={SetContactDetailsEventHandler}
                                                          id={"GithubPageIcon"}/>
                                            </Stack>
                                        </Box>
                                    </Box>
                                    <Stack flex={"2.5"} id={"ContactInformationPane"}>
                                        <Box>
                                            <img src={"ContactDetailsPaneHeader.png"} id={"ContactRightPaneHeader"}/>
                                        </Box>
                                        <Box id={"ContactDetailsPaneText"}>
                                            {contactDetails === "Email" &&
                                                <>
                                                    <Text fontSize={"3xl"}>
                                                        {contactDetails}
                                                    </Text>
                                                    <Text>
                                                        If you want to get in with touch me, please feel free to shoot
                                                        me an email! Im always looking for a new challenge and am excited
                                                        to meet new people.
                                                    </Text>
                                                    <br/>
                                                    <Text>
                                                        Click the link below to copy my email to your clipboard!
                                                    </Text>
                                                    <br/>
                                                    <Link href={"#"}
                                                          color='blue'
                                                          fontSize={"xl"}
                                                          onClick={() => {
                                                              navigator.clipboard.writeText("rowley1920@saskpolytech.ca").
                                                              then(r => console.log())
                                                              if (!toast.isActive("CopyEmail"))
                                                              {
                                                                  toast({
                                                                      id: "CopyEmail",
                                                                      position: 'top-right',
                                                                      render: () => (
                                                                          <Box id={"Toast"}
                                                                               p={"3"}>
                                                                              Copied Email to Clipboard
                                                                          </Box>
                                                                      ),
                                                                  })
                                                              }
                                                          }}>
                                                        Copy Email to Clipboard!
                                                    </Link>
                                                </>
                                            }

                                            {contactDetails === "LinkedIn" &&
                                                <>
                                                    <Text fontSize={"3xl"}>
                                                        {contactDetails}
                                                    </Text>
                                                    <Text>
                                                        Here is my LinkedIn! If you have a business inquiry or want to
                                                        see more information about me, feel free to visit my LinkedIn.
                                                    </Text>
                                                    <br/>
                                                    <Text>
                                                        You can use the link below to access my LinkedIn.
                                                    </Text>
                                                    <br/>
                                                    <Link href={"#"}
                                                          fontSize={"xl"}
                                                          onClick={() => {window.open("https://www.linkedin.com/in/zachary-rowley-9b6432272/?original_referer=")}}
                                                          color='blue'>
                                                        LinkedIn
                                                    </Link>
                                                </>
                                            }

                                            {contactDetails === "GitHub" &&
                                                <>
                                                    <Text fontSize={"3xl"}>
                                                        {contactDetails}
                                                    </Text>
                                                    <Text>
                                                        Here is my Github! You can take a look at all the projects I have
                                                        worked on (or at least the projects I have uploaded) using the link
                                                        below.
                                                    </Text>
                                                    <br/>
                                                    <Link href={"#"}
                                                          fontSize={"xl"}
                                                          onClick={() => {window.open("https://github.com/SabishiiMe")}}
                                                          color='blue'>
                                                        GitHub
                                                    </Link>
                                                </>
                                            }
                                        </Box>
                                    </Stack>
                                </Flex>
                            </Box>
                            {/*<Box id={"ContactBottomBar"}>*/}
                            {/*    <Text fontSize={"6xl"}></Text>*/}
                            {/*</Box>*/}
                        </Stack>
                    </Box>
                }
                {props.WindowContent === "Skills" &&
                    <Box className={"SkillsContent"}>
                        <img src={"TEMPSKILLS.png"}/>
                    </Box>
                }
                {props.WindowContent === "Artifacts" &&
                    <Box className={"ArtifactsContent"}>
                        <img src={"TEMPARTOFACTS.png"}/>
                    </Box>
                }

            </Box>
            </Stack>
        </Box>
)}

export default Window